/**
 * @params 接收一个对象，返回拼接地址栏参数形式的字符串
 *
 */
export function setUrlParams(params) {
 let _newParams =  filterObj(params)
  let paramStr = ''
  Object.keys(_newParams).forEach((item) => {
    if (paramStr === '') {
      paramStr = `${item}=${_newParams[item]}`
    } else {
      paramStr = `${paramStr}&${item}=${_newParams[item]}`
    }
  })
  return `?` + paramStr
}

/**
 * 过滤对象中为空的属性
 * @param obj
 * @returns {*}
 */
 export function filterObj(obj) {
  if (!(typeof obj == 'object')) {
    return;
  }

  for (let key in obj) {
    if (obj.hasOwnProperty(key)
      && (obj[key] == null || obj[key] == undefined || obj[key] === '')) {
      delete obj[key];
    }
  }
  return obj;
}

/**
 * 获取当前时间， 格式“yyyy-MM-dd HH:MM:SS”
 * 
 */
 export function getNowFormatDateTime() {
  var date = new Date();
  var seperator1 = "-";
  var seperator2 = ":";
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var strHours = date.getHours();
  var strMinutes = date.getMinutes();
  var strSeconds = date.getSeconds();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  if (strHours >= 0 && strHours <= 9) {
    strHours = "0" + strHours;
  }
  if (strMinutes >= 0 && strMinutes <= 9) {
    strMinutes = "0" + strMinutes;
  }
  if (strSeconds >= 0 && strSeconds <= 9) {
    strSeconds = "0" + strSeconds;
  }
  var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate
    + " " + strHours + seperator2 + strMinutes
    + seperator2 + strSeconds;
  return currentdate;
}

