import request from "@/api/service";

const api = {
   page: '/pms/shiftrecord/getPage',
   detail: '/pms/shiftrecord/getDetail',
   export: '/pms/shiftrecord/export'
}

export function getPage(params) {
   return request({
      // url: api.page+ `?page=${params.page}&limit=${params.limit}&hotelId=${params.hotelId}&shift=${params.shift}&shiftStartTime=${params.shiftStartTime}&shiftEndTime=${params.shiftEndTime}`,
      url: api.page + params,
      method: 'POST',
   })
}

export function getDetail(query) {
   return request({
      url: api.detail + query,
      method: 'GET',
   })
}

export function exportExcel(data) {
   return request({
      url: api.export,
      method:'POST',
      responseType:'blob',
      data
   })
}
