<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <div class="content-box">
      <div class="search-box">
        <div class="search-row">
          <div class="search-item">
            <label>交班时间：</label>
            <!-- <date-packer align="left" :pickerOptions="pickerOptions" :datePacker="dateRange" @setDatePacker="getDatePacker" format="yyyy-MM-dd"></date-packer> -->
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :pickerOptions="pickerOptions"
              value-format="yyyy-MM-dd HH:mm:ss"
              @change="getDatePacker">
            </el-date-picker>
          </div>
          <div class="search-item">
            <label>交班班次：</label>
            <el-select v-model="shift">
              <el-option v-for="(val, key) in dictData['handover-shift']" :key="key" :label="val" :value="key"></el-option>
            </el-select>
          </div>
          <el-button class="bg-gradient" type="primary" @click="handleQuery(true)" >搜索</el-button>
          <el-button class="bg-gradient" type="primary" @click="handleQuery(false)" >重置</el-button>
          <el-button class="bg-gradient" type="primary" @click="exportShiftLog">导出</el-button>
        </div>
      </div>
      <el-row class="table-box">
        <el-table v-loading="loading"  :data="tableData" :stripe="true" border fit>
          <el-table-column label="交接班次">
            <template slot-scope="scope">{{ scope.row.shift | filterShift(that) }}</template>
          </el-table-column>
          <el-table-column prop="shiftPerson" label="交班人"></el-table-column>
          <el-table-column label="交班时间" width="320">
            <template slot-scope="scope">
              {{ scope.row.shiftStartTime }}~{{ scope.row.shiftEndTime }}
            </template>
          </el-table-column>
          <el-table-column prop="totalRevenue"  label="营收合计">
            <template slot-scope="scope">
              <span>{{scope.row.totalRevenue ? `￥${scope.row.totalRevenue}` : scope.row.totalRevenue}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="actuallyReceived" label="本班交款">
             <template slot-scope="scope">
              <span>{{scope.row.actuallyReceived ? `￥${scope.row.actuallyReceived}` : scope.row.actuallyReceived}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="handleDetail(scope.row.id)" type="text">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="handleCurrPage"/>
      </el-row>
    </div>
    <el-dialog title="查看详情" :visible.sync="visibleDetail" width="1200px">
      <div class="detail-pop-title">
        <h6>{{ hotelInfo.hotelName }}</h6>
      </div>
      <el-descriptions class="m-top-10 m-bottom-10" :column="3">
        <el-descriptions-item label="班次时间">{{ shiftStatistics.shiftStartTime }}至{{ shiftStatistics.shiftEndTime }}</el-descriptions-item>
        <el-descriptions-item label="交班人">{{ shiftStatistics.shiftPerson }}</el-descriptions-item>
        <el-descriptions-item label="选择班次">{{ shiftStatistics.shift | filterShift(that) }}</el-descriptions-item>
      </el-descriptions>
      <el-row type="flex">
        <el-table :data="[{ itemName: '营收合计', amount: shiftStatistics.totalRevenue }]" border >
          <el-table-column label="结算合计" header-align='center'>
            <el-table-column prop="itemName" label="项目"></el-table-column>
            <el-table-column prop="amount" label="金额（元）">
              <template slot-scope="scope">
              <span>{{scope.row.amount ? `￥${scope.row.amount}` : scope.row.amount}}</span>
            </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        <el-table :data="[{ itemName: '上班收款', amount: shiftStatistics.previousReceived },
                { itemName: '本班收款', amount: shiftStatistics.actuallyReceived },
                { itemName: '本班欠款', amount: shiftStatistics.actuallyArrears }]">
          <el-table-column label="交接钱款" header-align='center'>
            <el-table-column prop="itemName" label="项目"></el-table-column>
            <el-table-column prop="amount" label="金额（元）">
              <template slot-scope="scope">
              <span>{{scope.row.amount ? `￥${scope.row.amount}` : scope.row.amount}}</span>
            </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        <el-table :data="[{ itemName: '预定数', amount: shiftStatistics.predestineCount },
                           { itemName: '退房数', amount: shiftStatistics.checkoutCount },
                           { itemName: '入住数', amount: shiftStatistics.checkinCount },
                           { itemName: '打扫数', amount: shiftStatistics.cleanCount },
                           { itemName: '确认数', amount: shiftStatistics.confirmCount }]">
          <el-table-column label="当班操作" header-align='center'>
            <el-table-column prop="itemName" label="项目"></el-table-column>
            <el-table-column prop="amount" label="数量（间）"></el-table-column>
          </el-table-column>
        </el-table>
        <el-table :data="[{ itemName: '已发出房卡', amount: shiftStatistics.sendRoomCount }]">
          <el-table-column label="房卡合计" header-align='center'>
            <el-table-column prop="itemName" label="项目"></el-table-column>
            <el-table-column prop="amount" label="数量（间）"></el-table-column>
          </el-table-column>
        </el-table>
        <el-table :data="consumptionItems">
          <el-table-column label="商品销售" header-align='center'>
            <el-table-column prop="consumptionItemName" label="商品"></el-table-column>
            <el-table-column prop="quantity" label="数量"></el-table-column>
          </el-table-column>
        </el-table>
      </el-row>
      <span slot="footer">
          <el-button @click="visibleDetail = false">取消</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
import { getPage, getDetail, exportExcel } from "@/api/pms/shiftLog/shiftLog";
import { exportFile } from '@/common/js/common'
import getDict from "@/utils/dataDict";
import { mapState } from 'vuex';
import { setUrlParams } from '@/utils/tools'
export default {
  name: 'shiftLog',
  data() {
    return {
      crumbs: new Map([
          ['PMS'], ['交班日志']
      ]),
       pickerOptions: {
         shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }],
            disabledDate(date){
              return date.getTime() >= Date.now()
            }
         },
      that: this,
      loading: true,
      shift: '',
      dateRange: [],
      page: 1,
      limit: 1,
      tableData: [],
      total: 0,
      visibleDetail: false,
      shiftStatistics: {
        totalRevenue: 0,
        previousReceived: 0,
        actuallyReceived: 0,
        actuallyArrears: 0,
        predestineCount: 0,
        checkoutCount: 0,
        cleanCount: 0,
        checkinCount: 0,
        confirmCount: 0,
        sendRoomCount: 0,
        shiftPerson: '',
        shiftStartTime: '',
        shiftEndTime: '',
        shift: ''
      },
      consumptionItems: []
    }
  },
  computed: {
    ...mapState(['hotelInfo', 'dictData'])
  },
  watch: {
    'hotelInfo.id'(id) {
      this.getShiftLogPage()
    }
  },
  mounted() {
    this.limit = sessionStorage.getItem('pageSize') * 1;
    getDict('handover-shift')
    this.getShiftLogPage()
  },
  methods: {
    exportShiftLog() {
      if(!this.dateRange) {
        this.dateRange = [];
      }
      let pageQuery = {
        hotelId:this.hotelInfo.id,
        shift:this.shift,
        shiftStartTime: this.dateRange[0] ? this.dateRange[0]: '',
        shiftEndTime: this.dateRange[1] ?  this.dateRange[1] : '',
      }
      if (this.total) {
        exportExcel(pageQuery).then(res => {
          exportFile(res,'交班日志')
        })
      } else {
        this.$message({showClose: true,message:"没有数据可以导出",type: 'error'});
      }
    },
    getShiftLogPage() {
      if(!this.dateRange) {
        this.dateRange = [];
      }
      const params = {
        hotelId: this.hotelInfo.id,
        shift: this.shift,
        shiftStartTime: this.dateRange[0] ? this.dateRange[0]: '',
        shiftEndTime: this.dateRange[1] ?  this.dateRange[1] : '',
        page: this.page,
        limit: this.limit
      }
      getPage(setUrlParams(params)).then(({ success, records, total }) => {
        if (success) {
          this.total = total
          this.tableData = records
          this.loading = false
        }
      })
    },
    handleQuery(bool) {
      this.page = 1
      if (bool) {
        this.getShiftLogPage()
      } else {
        this.shift = ''
        this.dateRange = []
        this.getShiftLogPage()
      }
    },
    getDatePacker(val) {
      this.dateRange = val
    },
    changePageNum(num) {
      this.limit = num
      this.getShiftLogPage(num)
    },
    handleCurrPage(num) {
      this.page = num
      this.getShiftLogPage()
    },
    handleDetail(id) {
      getDetail(`/${ id }`).then(({ success, data }) => {
        if (success) {
          for (const k in this.shiftStatistics) {
            k in data.shift && (this.shiftStatistics[k] = data.shift[k])
          }
          this.consumptionItems = data.consumptionItems
          this.visibleDetail = true
        }
      })
    }
  },
  filters: {
    filterShift(val, that) {
      if (val && that.dictData['handover-shift']) return that.dictData['handover-shift'][val] || val
    }
  }
}
</script>
<style lang="scss" scoped>
.cont {
  .detail-pop-title {
    text-align: center;
    margin: 0 0 10px 0;
    h6 { font-size: 20px }
  }
  /deep/ .el-dialog__body{
    padding-top: 0;
  }
}
</style>
